'use strict';

angular.module('enervexSalesappApp').controller('BaffleTypeCtrl', function($scope, BaffleType, $stateParams, Flash, $state, _, VentMaterial, Util) {
	$scope.errors = {};
	$scope.ctx = {
		formChanged: false
	}
	$scope.itemCodeDisabled = true
	$scope.codeWarning = Util.setCodeWarning()
	$scope.disableItemCode = function(){
		if ($scope.itemCodeDisabled === true) {
			$scope.itemCodeDisabled = false
		} else {
			$scope.itemCodeDisabled = true
		}
	}
	if ($stateParams.id == "new") {
		$scope.baffleType = {
			name: "",
			sortOrder: 0,
			ventMaterials:[]
		}
		$scope.itemCodeDisabled = false
		fetchMaterials()
	} else {
		BaffleType.get({
			id: $stateParams.id
		}).$promise.then(function(baffleType) {
			$scope.baffleType = baffleType;
			fetchMaterials()
		})
	}
	function fetchMaterials() {
		VentMaterial.query().$promise.then(function(result){
			$scope.ventMaterials = _.map(result, function(ventMaterial){
				var existing = _.find($scope.baffleType.ventMaterials, function(vm){
					return vm.code == ventMaterial.code
				})
				if (existing){
					ventMaterial.selected = true
				}
				return ventMaterial;
			})
		})
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.baffleType);
			payload = _.omit(payload, "__v");
			payload.ventMaterials = _.filter($scope.ventMaterials, function(vm){
				return vm.selected;
			})
			if (!$scope.baffleType._id) {
				BaffleType.save(payload).$promise.then(function(result) {
					
					$scope.success = true;
					// $scope.baffleType = result;
					Flash.create('success', '<strong>Success!</strong> Successfully created baffleType.', 0, {}, true);
					$state.go("baffle-type", {
						id: result._id
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				BaffleType.update({
					id: $scope.baffleType._id
				}, payload).$promise.then(function(baffleType) {
					
					$scope.success = true;
					// $scope.baffleType = baffleType;
					Flash.create('success', '<strong>Success!</strong> Successfully updated baffleType.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});
